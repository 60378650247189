define("discourse/plugins/memconnect/initializers/memconnect", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeMemconnect(api) {
    // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/lib/plugin-api.js.es6
  }
  var _default = _exports.default = {
    name: "memconnect",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeMemconnect);
    }
  };
});